import styles from './resume.module.css';

function TechSkills() {

    return (

        <div className={styles.techSkills}>
            <div className={styles.scroller}>
                <svg width="8640" height="200" viewBox="0 0 8640 200" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M144.72 196.136H85.7522V36.509H0V3.86409H230.472V36.509H144.72V196.136Z" fill="white" />
                    <path d="M476.724 163.491V196.136H287.779V3.86409H476.724V36.509H346.954V83.8108H469.042V114.057H346.954V163.491H476.724Z" fill="white" />
                    <path d="M647.605 166.023C678.543 166.023 697.853 152.165 698.475 129.78H758.274C758.274 173.351 715.917 200 647.813 200C573.688 200 530.086 170.286 530.086 119.654V80.3464C530.086 29.7135 573.688 0 647.813 0C716.747 0 757.651 26.5157 758.066 71.952H698.268C697.645 48.9007 677.297 33.9773 646.775 33.9773C610.439 33.9773 591.337 49.9667 591.337 80.3464V119.654C591.337 150.167 610.439 166.023 647.605 166.023Z" fill="white" />
                    <path d="M1010.75 196.136H952.825V114.857H854.823V196.136H796.893V3.86409H854.823V82.2119H952.825V3.86409H1010.75V196.136Z" fill="white" />
                    <path d="M1112.7 196.136H1061.62V3.86409H1114.16L1217.14 131.646H1220.67V3.86409H1271.75V196.136H1219.43L1116.23 67.555H1112.7V196.136Z" fill="white" />
                    <path d="M1521.12 196.136H1337.98V163.624H1400.07V36.3757H1337.98V3.86409H1521.12V36.3757H1459.03V163.624H1521.12V196.136Z" fill="white" />
                    <path d="M1699.06 166.023C1729.99 166.023 1749.3 152.165 1749.93 129.78H1809.72C1809.72 173.351 1767.37 200 1699.26 200C1625.14 200 1581.54 170.286 1581.54 119.654V80.3464C1581.54 29.7135 1625.14 0 1699.26 0C1768.2 0 1809.1 26.5157 1809.52 71.952H1749.72C1749.1 48.9007 1728.75 33.9773 1698.23 33.9773C1661.89 33.9773 1642.79 49.9667 1642.79 80.3464V119.654C1642.79 150.167 1661.89 166.023 1699.06 166.023Z" fill="white" />
                    <path d="M1920.39 119.52H1985.8L1954.65 41.5723H1951.95L1920.39 119.52ZM2016.32 196.136L1997.63 149.101H1908.56L1889.45 196.136H1830.49L1920.39 3.86409H1990.16L2080.06 196.136H2016.32Z" fill="white" />
                    <path d="M2324.24 162.692V196.136H2134.67V3.86409H2193.01V162.692H2324.24Z" fill="white" />
                    <path d="M2632.16 142.305H2687.8C2690.5 158.961 2711.68 168.554 2745.73 168.554C2778.12 168.554 2798.68 158.561 2798.68 142.971C2798.68 129.913 2785.39 122.985 2750.71 118.055L2719.57 113.391C2665.38 105.53 2638.8 87.1419 2638.8 58.6276C2638.8 22.7848 2680.12 0 2744.9 0C2807.61 0 2849.75 22.5183 2851.62 56.8954H2795.98C2793.69 41.0393 2774.8 31.3125 2745.52 31.3125C2715.83 31.3125 2697.35 40.2398 2697.35 54.6302C2697.35 68.2212 2710.64 75.6829 2743.03 80.2132L2773.76 84.7435C2831.69 93.0047 2857.02 109.66 2857.02 139.107C2857.02 177.082 2814.66 200 2744.69 200C2676.8 200 2634.23 178.281 2632.16 142.305Z" fill="white" />
                    <path d="M2964.16 196.136H2908.1V3.86409H2964.16V91.4057H2967.27L3072.13 3.86409H3134.42L3031.23 88.7408L3141.06 196.136H3072.34L2990.53 116.056L2964.16 137.642V196.136Z" fill="white" />
                    <path d="M3361.15 196.136H3178.02V163.624H3240.1V36.3757H3178.02V3.86409H3361.15V36.3757H3299.07V163.624H3361.15V196.136Z" fill="white" />
                    <path d="M3638.55 162.692V196.136H3448.98V3.86409H3507.33V162.692H3638.55Z" fill="white" />
                    <path d="M3901.41 162.692V196.136H3711.84V3.86409H3770.19V162.692H3901.41Z" fill="white" />
                    <path d="M3946.47 142.305H4002.11C4004.81 158.961 4025.99 168.554 4060.04 168.554C4092.43 168.554 4112.99 158.561 4112.99 142.971C4112.99 129.913 4099.7 122.985 4065.03 118.055L4033.88 113.391C3979.69 105.53 3953.11 87.1419 3953.11 58.6276C3953.11 22.7848 3994.43 0 4059.21 0C4121.92 0 4164.07 22.5183 4165.94 56.8954H4110.29C4108.01 41.0393 4089.11 31.3125 4059.84 31.3125C4030.15 31.3125 4011.67 40.2398 4011.67 54.6302C4011.67 68.2212 4024.95 75.6829 4057.34 80.2132L4088.07 84.7435C4146 93.0047 4171.34 109.66 4171.34 139.107C4171.34 177.082 4128.98 200 4059.01 200C3991.11 200 3948.55 178.281 3946.47 142.305Z" fill="white" />
                    <path d="M4613.38 196.136H4554.42V36.509H4468.66V3.86409H4699.14V36.509H4613.38V196.136Z" fill="white" />
                    <path d="M4945.39 163.491V196.136H4756.44V3.86409H4945.39V36.509H4815.62V83.8108H4937.71V114.057H4815.62V163.491H4945.39Z" fill="white" />
                    <path d="M5116.27 166.023C5147.21 166.023 5166.52 152.165 5167.14 129.78H5226.94C5226.94 173.351 5184.58 200 5116.48 200C5042.35 200 4998.75 170.286 4998.75 119.654V80.3464C4998.75 29.7135 5042.35 0 5116.48 0C5185.41 0 5226.32 26.5157 5226.73 71.952H5166.93C5166.31 48.9007 5145.96 33.9773 5115.44 33.9773C5079.1 33.9773 5060 49.9667 5060 80.3464V119.654C5060 150.167 5079.1 166.023 5116.27 166.023Z" fill="white" />
                    <path d="M5479.42 196.136H5421.49V114.857H5323.49V196.136H5265.56V3.86409H5323.49V82.2119H5421.49V3.86409H5479.42V196.136Z" fill="white" />
                    <path d="M5581.37 196.136H5530.29V3.86409H5582.82L5685.81 131.646H5689.34V3.86409H5740.41V196.136H5688.09L5584.9 67.555H5581.37V196.136Z" fill="white" />
                    <path d="M5989.78 196.136H5806.65V163.624H5868.73V36.3757H5806.65V3.86409H5989.78V36.3757H5927.7V163.624H5989.78V196.136Z" fill="white" />
                    <path d="M6167.72 166.023C6198.66 166.023 6217.97 152.165 6218.59 129.78H6278.39C6278.39 173.351 6236.03 200 6167.93 200C6093.8 200 6050.2 170.286 6050.2 119.654V80.3464C6050.2 29.7135 6093.8 0 6167.93 0C6236.86 0 6277.77 26.5157 6278.18 71.952H6218.38C6217.76 48.9007 6197.41 33.9773 6166.89 33.9773C6130.55 33.9773 6111.45 49.9667 6111.45 80.3464V119.654C6111.45 150.167 6130.55 166.023 6167.72 166.023Z" fill="white" />
                    <path d="M6389.06 119.52H6454.46L6423.32 41.5723H6420.62L6389.06 119.52ZM6484.98 196.136L6466.3 149.101H6377.22L6358.12 196.136H6299.15L6389.06 3.86409H6458.82L6548.73 196.136H6484.98Z" fill="white" />
                    <path d="M6792.9 162.692V196.136H6603.33V3.86409H6661.68V162.692H6792.9Z" fill="white" />
                    <path d="M7100.82 142.305H7156.47C7159.17 158.961 7180.34 168.554 7214.4 168.554C7246.79 168.554 7267.34 158.561 7267.34 142.971C7267.34 129.913 7254.05 122.985 7219.38 118.055L7188.23 113.391C7134.04 105.53 7107.46 87.1419 7107.46 58.6276C7107.46 22.7848 7148.78 0 7213.57 0C7276.27 0 7318.42 22.5183 7320.29 56.8954H7264.64C7262.36 41.0393 7243.46 31.3125 7214.19 31.3125C7184.5 31.3125 7166.02 40.2398 7166.02 54.6302C7166.02 68.2212 7179.31 75.6829 7211.7 80.2132L7242.43 84.7435C7300.36 93.0047 7325.69 109.66 7325.69 139.107C7325.69 177.082 7283.33 200 7213.36 200C7145.46 200 7102.9 178.281 7100.82 142.305Z" fill="white" />
                    <path d="M7432.82 196.136H7376.76V3.86409H7432.82V91.4057H7435.94L7540.79 3.86409H7603.08L7499.89 88.7408L7609.73 196.136H7541L7459.19 116.056L7432.82 137.642V196.136Z" fill="white" />
                    <path d="M7829.82 196.136H7646.69V163.624H7708.77V36.3757H7646.69V3.86409H7829.82V36.3757H7767.74V163.624H7829.82V196.136Z" fill="white" />
                    <path d="M8107.21 162.692V196.136H7917.65V3.86409H7975.99V162.692H8107.21Z" fill="white" />
                    <path d="M8370.08 162.692V196.136H8180.51V3.86409H8238.85V162.692H8370.08Z" fill="white" />
                    <path d="M8415.13 142.305H8470.78C8473.48 158.961 8494.66 168.554 8528.71 168.554C8561.1 168.554 8581.66 158.561 8581.66 142.971C8581.66 129.913 8568.37 122.985 8533.69 118.055L8502.55 113.391C8448.36 105.53 8421.78 87.1419 8421.78 58.6276C8421.78 22.7848 8463.1 0 8527.88 0C8590.58 0 8632.73 22.5183 8634.6 56.8954H8578.96C8576.67 41.0393 8557.78 31.3125 8528.5 31.3125C8498.81 31.3125 8480.33 40.2398 8480.33 54.6302C8480.33 68.2212 8493.62 75.6829 8526.01 80.2132L8556.74 84.7435C8614.67 93.0047 8640 109.66 8640 139.107C8640 177.082 8597.64 200 8527.67 200C8459.77 200 8417.21 178.281 8415.13 142.305Z" fill="white" />
                </svg>
            </div>
        </div>

    );
}

export default TechSkills;